
import { Model, Attribute, BelongsTo } from './decorators';
import { BaseApiModel } from './base';
import { DocumentTypeFieldsetType, ExtractionTypeType } from '@parashift/shared/types';
import { ExtractionType } from './extraction-type.model';
import { Visibility } from './document-type.model';
import { Locale } from './../interfaces/locale.interface';
import { BusinessSector } from './../interfaces/business-sector.interface';

const DocumentTypeFieldsetStatus = {
  draft: $localize `:@@common.draft:Draft` as 'draft',
  test: $localize `:@@common.test:Test/Training` as 'test',
  production: $localize `:@@common.production:Production` as 'production',
  deactivated: $localize `:@@common.deactivated:Deactivated` as 'deactivated',
};
type DocumentTypeFieldsetStatus = (typeof DocumentTypeFieldsetStatus)[keyof typeof DocumentTypeFieldsetStatus];
export { DocumentTypeFieldsetStatus };

export interface DocumentTypeFieldsetPlainModel {
  id?: string;
  business_sectors?: BusinessSector[];
  created_at?: string;
  description?: string;
  extraction_type_id?: string;
  identifier?: string;
  input_arguments?: string;
  locales?: Locale[];
  name?: string;
  parent_id?: number;
  root_id?: number;
  status?: DocumentTypeFieldsetStatus;
  tags: string[];
  target_id?: number;
  tenant_id?: number;
  updated_at?: string;
  visibility?: Visibility;

  extraction_type?: ExtractionType['plainModel'] | Partial<ExtractionType['plainModel']>;
  parent?: DocumentTypeFieldset['plainModel'] | Partial<DocumentTypeFieldset['plainModel']>;
}

@Model({ type: DocumentTypeFieldsetType })
export class DocumentTypeFieldset extends BaseApiModel<DocumentTypeFieldsetPlainModel> {

  @Attribute()
  business_sectors: BusinessSector[];

  @Attribute()
  created_at: string;

  @Attribute()
  description: string;

  @Attribute()
  extraction_type_id: number;

  @Attribute()
  identifier: string;

  @Attribute()
  input_arguments: object;

  @Attribute()
  locales: Locale[];

  @Attribute()
  name: string;

  @Attribute()
  parent_id: number;

  @Attribute()
  root_id: number;

  @Attribute()
  status: DocumentTypeFieldsetStatus;

  @Attribute()
  tags: string[];

  @Attribute()
  target_id: number;

  @Attribute()
  tenant_id: number;

  @Attribute()
  updated_at: string;

  @Attribute()
  visibility: Visibility;

  @BelongsTo({ class: ExtractionTypeType })
  extraction_type: ExtractionType;

  @BelongsTo({ class: DocumentTypeFieldsetType, sidepostable: false })
  parent: (DocumentTypeFieldset | Partial<DocumentTypeFieldset>);

  getType(currentTenantId: number): 'standard' | 'individual' {
    if (!this.parent_id && this.tenant_id === currentTenantId) {
      return 'individual';
    } else {
      return 'standard';
    }
  }

  getAllowEditFlag() {
    return this.parent_id ? false : true;
  }

  get plainModel(): DocumentTypeFieldsetPlainModel {
    const model = this.toHash() as DocumentTypeFieldsetPlainModel;
    model.extraction_type_id = model.extraction_type_id ? String(model.extraction_type_id) : '';
    model.input_arguments = this.getInputArguments();

    return model;
  }

  set plainModel(model) {
    this.customUpdates = {
      input_arguments: setInputArguments
    };

    this.setPlainModel(model);
  }

  getInputArguments(): string {
    if (!this.input_arguments) {
      return '';
    }
    return JSON.stringify(this.input_arguments);
  }
}

function setInputArguments(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string) {
  if (updatedPlainModel[key]) {
    existingModel[key] = JSON.parse(updatedPlainModel[key]);
  } else {
    existingModel[key] = null;
  }
}
